<template>
	<div class="mtipobultoFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipobulto.idtipbulto')" :value="getIdtipbultoValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipobulto.codtipbultoedi')" :value="getCodtipbultoediValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipobulto.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mtipobulto-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mtipobulto'
		};
	},
	computed: {
		getIdtipbultoValue() {
			return this.model && this.model.idtipbulto ? this.model.idtipbulto : '-';
		},
		getCodtipbultoediValue() {
			return this.model && this.model.codtipbultoedi ? this.model.codtipbultoedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
